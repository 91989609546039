// import node_modules
import PropTypes from 'prop-types';
import React from 'react';

// import styles
import { Section } from './styles';

// Portrait component
const Portrait = ({ backgroundImage }) => <Section image={backgroundImage} />;

Portrait.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
};

export default Portrait;
