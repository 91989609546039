// import node_modules
import styled from 'styled-components';

// import styles
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';
import transitions from '../../styles/transitions';
import { mainHeadlineStyle, defaultTextStyle, labelStyle } from '../../styles/fonts';

// define styles
export const Section = styled.section`
  align-content: center;
  align-items: center;
  background-color: ${colors.white};
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: calc(100vh - 70px);
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    height: 100%;
    min-height: 80vh;
  }
`;

export const SectionContainer = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 720px;
  padding: 3rem 1rem;
`;

export const SectionTitle = styled.h1`
  ${mainHeadlineStyle}

  color: ${colors.black};
  font-size: 44px;
  margin: 0 0 1rem;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 28px;
    margin: 2rem 0 1rem;
  }
`;

export const SectionDescription = styled.div`
  ${defaultTextStyle}

  color: ${colors.dark};
  font-size: 26px;
  margin: 0 0 2rem;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 20px;
    margin: 0 0 1rem;
  }
`;

export const SectionButtonText = styled.span``;

export const SectionButtonIcon = styled.span`
  margin-top: 0.5rem;
`;

export const SectionButton = styled.button`
  ${labelStyle}

  align-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  justify-content: center;
  outline: 0;
  transition: all ${transitions.easeIn};

  &:hover {
    color: ${colors.link};
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 20px;
  }
`;
