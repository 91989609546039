// import node_modules
import React from 'react';

// import components
import About from '../components/About';
import Contact from '../components/Contact';
import Layout from '../components/Layout';
import Philosophy from '../components/Philosophy';
import Strengths from '../components/Strengths';
import Welcome from '../components/Welcome';

// import content
import content from '../content/home.json';

// Home page
const Home = () => {
  return (
    <Layout
      description={content.seo.description}
      image={content.seo.image}
      pathname="/"
      title={content.seo.title}
    >
      <Welcome
        backgroundImage={content.welcome.image}
        description={content.welcome.description}
        headline={content.welcome.headline}
        nextSection="about"
        section="welcome"
      />
      <About
        ctaLabel={content.about.linkedin.label}
        ctaLink={content.about.linkedin.link}
        portrait={content.about.image}
        section="about"
        text={content.about.text}
      />
      <Strengths
        backgroundImage={content.strengths.image}
        headline={content.strengths.headline}
        section="strengths"
        strengths={content.strengths.items}
      />
      <Philosophy
        description={content.philosophy.description}
        headline={content.philosophy.headline}
        section="philosophy"
      />
      <Contact
        ctaLabel={content.contact.linkedin.label}
        ctaLink={content.contact.linkedin.link}
        headline={content.contact.headline}
        section="contact"
      />
    </Layout>
  );
};

export default Home;
