// import node_modules
import styled from 'styled-components';

// import styles
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';
import { mainHeadlineStyle, defaultTextStyle } from '../../styles/fonts';

// define styles
export const Section = styled.section`
  align-content: center;
  align-items: center;
  background-color: ${colors.dark};
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SectionContainer = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 720px;
  padding: 5rem 1rem;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    padding: 3rem 1rem;
  }
`;

export const SectionTitle = styled.h1`
  ${mainHeadlineStyle}

  color: ${colors.white};
  font-size: 36px;
  margin: 0 0 1rem;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 28px;
  }
`;

export const SectionDescription = styled.div`
  ${defaultTextStyle}

  color: ${colors.light};
  font-size: 24px;
  margin: 0 0 1rem;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 20px;
  }
`;
