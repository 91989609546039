// import node_modules
import styled from 'styled-components';

// import styles
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';
import transitions from '../../styles/transitions';
import { defaultTextStyle, labelStyle } from '../../styles/fonts';

// define styles
export const Section = styled.section`
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 5rem 0;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin: 1rem 0;
  }
`;

export const SectionContainer = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 720px;
  padding: 1rem;
`;

export const SectionText = styled.div`
  ${defaultTextStyle}

  font-size: 24px;
  margin: 0 0 2rem;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 20px;
  }
`;

export const SectionCta = styled.a`
  ${labelStyle}

  align-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${colors.link};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  justify-content: center;
  outline: 0;
  text-align: center;
  text-decoration: none;
  transition: all ${transitions.easeIn};

  &:hover {
    color: ${colors.dark};
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 20px;
  }
`;

export const SectionCtaWrapper = styled.div`
  margin-bottom: 3rem;
`;

export const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
