// import node_modules
import styled from 'styled-components';

// import styles
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';
import { mainHeadlineStyle, defaultTextStyle } from '../../styles/fonts';

// define styles
export const Section = styled.section`
  align-content: center;
  align-items: center;
  background-color: ${colors.white};
  background-image: ${({ image }) => `url(${image})`};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const SectionContainer = styled.div`
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1024px;
  padding: 5rem 1rem 0;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    flex-direction: column;
    padding: 1rem 1rem 0;
  }
`;

export const SectionTitle = styled.h2`
  ${mainHeadlineStyle}

  font-size: 36px;
  margin: 0 0 2rem;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 28px;
  }
`;

export const StrenghtsContainer = styled.div`
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1024px;
  padding: 0 1rem 5rem;

  @media only screen and (max-width: ${breakpoints.untilMobile}) {
    flex-direction: column;
  }
`;

export const Strength = styled.div`
  flex: 1;
  padding: 0.5rem;
  position: relative;

  @media only screen and (max-width: ${breakpoints.untilMobile}) {
    max-width: inherit;
  }
`;

export const StrengthIcon = styled.img`
  filter: brightness(0) invert(1);
  height: auto;
  width: 35px;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    width: 25px;
  }
`;

export const StrengthImage = styled.img`
  height: auto;
  width: 100%;
`;

export const StrengthIconContainer = styled.div`
  align-content: center;
  align-items: center;
  background-color: ${colors.transparent};
  display: flex;
  height: 80px;
  justify-content: center;
  left: 0;
  margin-left: calc(50% - 40px);
  margin-top: 50%;
  position: absolute;
  top: -15px;
  width: 80px;
`;

export const StrengthContent = styled.div`
  background-color: ${colors.light};
  margin-top: 0.25rem;
  padding: 4rem 1rem 1rem;
`;

export const StrengthName = styled.h3`
  ${mainHeadlineStyle}

  font-size: 24px;
  margin: 0 0 1rem;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 20px;
  }
`;

export const StrengthText = styled.div`
  ${defaultTextStyle}

  font-size: 18px;
  margin: 0 0 2rem;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 16px;
  }
`;
