// import node_modules
import PropTypes from 'prop-types';
import React from 'react';

// import styles
import { Section, SectionCta, SectionContainer, SectionTitle } from './styles';

// Contact component
const Contact = ({ ctaLabel, headline, ctaLink, section }) => (
  <Section id={section}>
    <SectionContainer>
      <SectionTitle data-aos="fade-up">{headline}</SectionTitle>
      <SectionCta data-aos="fade-up" href={ctaLink} target="_blank" rel="noopener noreferrer">
        {ctaLabel}
      </SectionCta>
    </SectionContainer>
  </Section>
);

Contact.propTypes = {
  ctaLabel: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
};

export default Contact;
