// import node_modules
import PropTypes from 'prop-types';
import React from 'react';

// import styles
import { Section, SectionContainer, SectionDescription, SectionTitle } from './styles';

// Philosophy component
const Philosophy = ({ description, headline, section }) => (
  <Section id={section}>
    <SectionContainer>
      <SectionTitle data-aos="fade-up">{headline}</SectionTitle>
      <SectionDescription data-aos="fade-up">{description}</SectionDescription>
    </SectionContainer>
  </Section>
);

Philosophy.propTypes = {
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
};

export default Philosophy;
