/* eslint-disable import/prefer-default-export */

// import node_modules
import styled from 'styled-components';

// import styles
import colors from '../../styles/colors';
import breakpoints from '../../styles/breakpoints';

// define styles
export const Section = styled.section`
  background-color: ${colors.white};
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    height: 100%;
    min-height: 480px;
  }
`;
