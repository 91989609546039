// import node_modules
import PropTypes from 'prop-types';
import React from 'react';

// import styles
import {
  Section,
  SectionContainer,
  SectionTitle,
  StrenghtsContainer,
  Strength,
  StrengthContent,
  StrengthIcon,
  StrengthIconContainer,
  StrengthImage,
  StrengthName,
  StrengthText,
} from './styles';

// Strengths component
const Strengths = ({ backgroundImage, headline, section, strengths }) => (
  <Section id={section} image={backgroundImage}>
    <SectionContainer>
      <SectionTitle data-aos="fade-up">{headline}</SectionTitle>
    </SectionContainer>
    <StrenghtsContainer>
      {strengths.map(strength => (
        <Strength key={strength.id} data-aos="fade-up">
          <StrengthImage alt={strength.name} src={strength.image} />
          <StrengthIconContainer>
            <StrengthIcon alt={strength.name} src={strength.icon} />
          </StrengthIconContainer>
          <StrengthContent>
            <StrengthName>{strength.name}</StrengthName>
            <StrengthText>{strength.text}</StrengthText>
          </StrengthContent>
        </Strength>
      ))}
    </StrenghtsContainer>
  </Section>
);

Strengths.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  strengths: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Strengths;
