// import node_modules
import styled from 'styled-components';

// import styles
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';
import fonts, { mainHeadlineStyle } from '../../styles/fonts';
import shadows from '../../styles/shadows';
import transitions from '../../styles/transitions';

// define styles
export const Section = styled.section`
  align-content: center;
  align-items: center;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  padding: 5rem 0;
  width: 100%;
`;

export const SectionContainer = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 720px;
`;

export const SectionTitle = styled.h2`
  ${mainHeadlineStyle}

  font-size: 36px;
  margin: 0 0 1rem;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    font-size: 28px;
  }
`;

export const SectionCta = styled.a`
  background-color: ${colors.link};
  border: none;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: ${fonts.families.default};
  font-size: 20px;
  font-weight: ${fonts.weights.bold};
  justify-content: center;
  letter-spacing: ${fonts.letterSpacings.labels};
  line-height: ${fonts.lineHeights.labels};
  outline: 0;
  padding: 1.2rem 2rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all ${transitions.easeOut};

  &:hover {
    box-shadow: ${shadows.default};
  }
`;
