// import node_modules
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

// import styles
import {
  Section,
  SectionButton,
  SectionButtonIcon,
  SectionButtonText,
  SectionContainer,
  SectionDescription,
  SectionTitle,
} from './styles';

// declare React component
const Welcome = ({ backgroundImage, description, headline, nextSection, section }) => {
  // init func
  const onScrollToSection = useCallback(scrollTo => {
    const element = document.getElementById(scrollTo);
    element && element.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, []);

  // init render
  return (
    <Section id={section} image={backgroundImage}>
      <SectionContainer>
        <SectionTitle>{headline}</SectionTitle>
        <SectionDescription>{description}</SectionDescription>
        <SectionButton onClick={() => onScrollToSection(nextSection)} type="button">
          <SectionButtonText>Scroll Down</SectionButtonText>
          <SectionButtonIcon>&darr;</SectionButtonIcon>
        </SectionButton>
      </SectionContainer>
    </Section>
  );
};

Welcome.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  nextSection: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
};

export default Welcome;
