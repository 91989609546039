// import node_modules
import PropTypes from 'prop-types';
import React from 'react';

// import components
import Portrait from '../Portrait';

// import styles
import {
  AboutWrapper,
  Section,
  SectionContainer,
  SectionCta,
  SectionCtaWrapper,
  SectionText,
} from './styles';

// declare component
const About = ({ portrait, text, section, ctaLink, ctaLabel }) => (
  <AboutWrapper id={section}>
    <Portrait backgroundImage={portrait} />
    <Section>
      <SectionContainer>
        <SectionText data-aos="fade-up" dangerouslySetInnerHTML={{ __html: text }} />
        <SectionCtaWrapper>
          <SectionCta data-aos="fade-up" href={ctaLink} target="_blank" rel="noopener noreferrer">
            {ctaLabel}
          </SectionCta>
        </SectionCtaWrapper>
      </SectionContainer>
    </Section>
  </AboutWrapper>
);

About.propTypes = {
  ctaLabel: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
  portrait: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default About;
